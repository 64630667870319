@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.detail-label {
    text-transform: uppercase;
    color: var(--v-custom-grey-base);
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 500;
}
