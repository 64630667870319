@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.search-results-detail {
    // Overflow-by-word
    // Note: While word-break: break-word is deprecated, it has the same effect, when specified,
    // as word-break: normal and overflow-wrap: anywhere
    word-break: normal;
    overflow-wrap: anywhere;
}
