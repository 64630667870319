@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// Support for hover interaction
//
// Detail: Removes the background of the v-menu and v-card, so the div at the top of
// the navigation is transparent.
// This serves the purpose of allow to transition from the 'hover' event of the button to the navigation menu
// TODO: This code is repeated so something is not quiet right.
.app-bar-menu {
    box-shadow: none;
    background: none;

    .v-card {
        background: none;
    }
}
