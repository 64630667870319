@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.download-button {
    text-transform: none;

    &:hover {
        cursor: pointer;
    }
}
