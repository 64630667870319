@import '~@/styles/mixins';
@import '~@/styles/helpers';
@import '~@/styles/typography';

/**

  Make the scrolling on the page be automatically decided based on the length of the content, e.g.:
    - if the entire content can fit on the scree, do not show a scrollbar
    - if the content on the page is longer and doesn't fit on the page, show a scrollbar

*/
html {
  overflow-y: auto !important;
}

/**
 Ordered numbered list container style, which will result in a structure like this:
     1. Base items are numbered with a decimal 1.
        i. First level sub items have lower roman numbers
        ii. Second item
            a. Second sub items have lowercase letters (a, b, c)
            b. Another item
     2. Second base item
        i. First level sub item
            a. Second level sub item
 */
.numbered-list-container {
  ol {
    list-style-type: decimal;

    li ol {
      list-style-type: lower-roman;

      li ol {
        list-style-type: lower-latin;
      }
    }
  }
}

//
// Global classes used in the hip/shoulder/spine planning
//

/*
 Background color for v-cards to match three js background color
 */
.three-js-background-color {
  background-color: #262626 !important;
}

/**
 Style for catstack container used on 3D planning page
 TODO: If can be extracted into a component move styles to styles component
 */
.catstack-container {
  margin-bottom: 40px;
  position: fixed;
  z-index: 10;
  bottom: 0;
}
