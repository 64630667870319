@mixin small-text() {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: var(--v-custom-grey-base);
}

@mixin small-uppercase-text() {
  text-transform: uppercase;
  @include small-text;
}

//body, select, input, textarea {
//  color: var(--v-custom-grey-body-base);
//  font-family: "proxima-nova", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
//  text-rendering: optimizeLegibility;
//  font-variant-ligatures: common-ligatures;
//}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  margin: 0 0 $var-layout-gap*2;
}

h1 {
  font-size: 1.75rem;
  line-height: 2.875rem;
  font-weight: 500;
  color: var(--v-custom-black-rich-base);
  //margin: 0 0 $gap*2;
}

h2 {
  font-size: 1.75rem;
  line-height: 2.875rem;
  color: var(--v-custom-black-base);
}

h3 {
  font-size: 1.313rem;
  line-height: 2.125rem;
  color: var(--v-custom-blue-base);

  &:before {
    content: '';
    width: 80px;
    height: 1px;
    background: var(--v-custom-blue-base);
    display: block;
    margin-bottom: $var-layout-gap*2;
  }
}

h4 {
  font-size: 1.313rem;
  line-height: 2.125rem;
  color: var(--v-custom-black-base);
}

.small-text {
  @include small-text;
}

h5, .small-uppercase-text {
  @include small-uppercase-text
}

.font-size-small {
  font-size: 0.75rem;
}

//p {
//  font-size: 0.875rem;
//  line-height: 1.438rem;
//  color: var(--v-custom-grey-base);
//  //margin: 0 0 $gap*2;
//}
//
//a {
//  text-decoration: none;
//  color: $c-w-blue;
//  &:hover {
//    color: $c-blue;
//  }
//}

//hr {
//  margin: $var-layout-gap*2 0;
//  border: none;
//  border-top: 1px solid $c-l-grey;
//}