@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.style-1 {
  background-color: rgb(215, 215, 44)
}

.style-2 {
  background-color: rgb(114, 114, 67)
}
