@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

/** @see https://stackoverflow.com/questions/16839284/underline-a-tag-when-hovering/16852308 */
.hyperlink {
    text-decoration: none;
}

.hyperlink:hover {
    text-decoration: underline;
}
