@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.patient-mismatch-container-title {
    word-break: normal;
    overflow-wrap: anywhere;
}

.field-has-error {
    color: var(--v-custom-red-base);
    text-decoration: underline wavy var(--v-custom-red-base);
}
