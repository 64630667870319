@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.dicom-card {
    // Using min-content allows the vuetify flex v-tabs to not add extra margin to the right of the layout
    width: min-content;

    // Using :deep selector to reach to vuetify v-tabs content
    :deep(.v-tabs-bar__content) {
        .v-tabs-slider-wrapper {
            transition: none;
            display: none;
        }

        .v-tab {
            background-color: var(--v-custom-grey-base);

            &.v-tab--active {
                background-color: var(--v-custom-black-base);
            }

            color: var(--v-custom-grey-body-base);
            text-transform: capitalize;
            font-size: 16px;
        }
    }
    :deep(.v-tabs-items) {
        background-color: var(--v-custom-black-base);
    }
}

