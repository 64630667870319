@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.main-view {
    background-color: var(--v-custom-black-base);
}

.secondary-view {
    background-color: var(--v-custom-black-rich-base);
}

.slider-container {
    background-color: var(--v-custom-black-rich-base);

    :deep(.v-label) {
        color: var(--v-custom-grey-body-base);
    }
}
