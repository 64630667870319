@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.create-new-case {
    height: 40px;
    width: 160px;

    font-size: 14px;
    font-weight: 500;

    .v-icon {
        font-size: 24px;
        margin-top: -12px;
    }
}
