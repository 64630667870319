@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


:deep(.v-list-item) {
    padding: 0 $var-layout-gap;

    .v-list-item__content, .v-list-item__title, .v-list-item__subtitle {
        font-size: 12px;
        font-weight: 400;
    }

    .v-list-item__title {
        //https://stackoverflow.com/questions/68893607/how-can-i-prevent-v-navigation-drawer-from-shortening-texts-inside-on-vuetify
        text-overflow: initial !important;
        white-space: initial !important;
    }
}
