<template>
    <app-page-holder v-if="!preferencesStore.isLoading" class="fill-height">
        <app-page-title
                ref="pageTitle"
                cy-data="prefs-page-title"
                title="Your Surgical Preferences"
                copy="These preferences will be applied to each new Formus Case you create. You can also override any of these settings per-Case after it has been created, and before it has been processed." />

        <app-card>
            <app-inline-notification
                    v-if="preferencesStore.isSubmitted"
                    ref="notification"
                    cy-data="prefs-page-notification"
                    :flavour="preferencesStore.hasError ? 'bad' : 'good'"
                    :title="preferencesStore.hasError
                        ? 'We’re sorry, there was an error communicating with the server.'
                        : 'Your Surgical Preferences were successfully updated!'"
                    :message="preferencesStore.hasError
                        ? 'Please try again, or contact the Formus support team via the help option at the top right of the page.'
                        : ''" />

            <app-inline-notification
                    v-else-if="preferencesStore.isDirty"
                    ref="notification"
                    cy-data="prefs-error-notification"
                    flavour="bad"
                    title="Please review your input"
                    message="Your changes were not saved. Please review the errors marked in red below and save again." />

            <app-form-section-title text="Stem" />
            <app-form-control label-text="Types">
                <app-checkbox-group
                        v-model="preferencesStore.preferences.stemTypes"
                        cy-data="stem-select-checkbox"
                        :disabled="preferencesStore.isSubmitting"
                        :options="preferencesStore.options.stemTypes"
                        :error="preferencesStore.validate('stemTypes', 'Please select at least one option')" />
            </app-form-control>

            <app-form-section-title text="Cup" />
            <app-form-control label-text="Inclination Angle">
                <app-input-text
                        v-model="preferencesStore.preferences.cupInclinationAngle"
                        cy-data="incl-angle-input"
                        unit="deg"
                        :disabled="preferencesStore.isSubmitting"
                        :error="preferencesStore.validate('cupInclinationAngle', 'Please provide a number')" />
            </app-form-control>

            <app-form-control label-text="Fitting Mode" cy-data="fitting-mode-buttons">
                <app-radio-group
                        v-model="preferencesStore.preferences.cupFittingMode"
                        :disabled="preferencesStore.isSubmitting"
                        :options="preferencesStore.options.fittingModes"
                        :error="preferencesStore.validate('cupFittingMode', 'Please select an option')" />
            </app-form-control>

            <div v-if="preferencesStore.preferences.cupFittingMode !== 'beverland'">
                <app-form-control label-text="Anteversion Mode" cy-data="antev-angle-buttons">
                    <app-radio-group
                            v-model="preferencesStore.preferences.cupAnteversionMode"
                            :disabled="preferencesStore.isSubmitting"
                            :options="preferencesStore.options.anteversionModes"
                            :error="preferencesStore.validate('cupAnteversionMode', 'Please select an option')" />
                </app-form-control>

                <app-form-control
                        v-if="preferencesStore.preferences.cupAnteversionMode === 'manual'"
                        label-text="Anteversion Angle"
                        cy-data="antev-angle-textinput">
                    <app-input-text
                            v-model="preferencesStore.preferences.cupAnteversionAngle"
                            unit="deg"
                            :disabled="preferencesStore.isSubmitting"
                            :error="preferencesStore.validate('cupAnteversionAngle', 'Please provide a number')" />
                </app-form-control>
            </div>
            <app-text v-else cy-data="beverland-notification" text="Cup anteversion angle is auto calculated for Beverland fit method" />
            <app-form-control cy-data="alignment-mode-buttons" label-text="Alignment Mode">
                <app-radio-group
                        v-model="preferencesStore.preferences.alignmentMode"
                        cy-data="alignment-mode-buttons"
                        :disabled="preferencesStore.isSubmitting"
                        :options="preferencesStore.options.alignmentModes"
                        :error="preferencesStore.validate('alignmentMode', 'Please select an option')" />
            </app-form-control>

            <app-form-actions>
                <app-form-status-notification
                        v-if="preferencesStore.hasChanged()"
                        flavour="warn"
                        cy-data="form-has-changes-warning"
                        message="You have unsaved changes on this page" />
                <app-button
                        :disabled="preferencesStore.isSubmitting || !preferencesStore.hasChanged()"
                        cy-data="prefs-submit-btn"
                        flavour="act"
                        label="Save your Surgical Preferences"
                        @click.native="preferencesStore.save($refs.pageTitle.$el)" />
            </app-form-actions>
        </app-card>
    </app-page-holder>
</template>

<script lang="ts">
    import { mapStores } from 'pinia';
    import { Component, Vue } from 'vue-property-decorator';

    import AppButton from 'sol-ui/src/components/basic/AppButton.vue';
    import AppCard from 'sol-ui/src/components/basic/AppCard.vue';
    import AppCheckboxGroup from 'sol-ui/src/components/inputs/AppCheckboxGroup.vue';
    import AppFormActions from 'sol-ui/src/components/basic/AppFormActions.vue';
    import AppFormControl from 'sol-ui/src/components/basic/AppFormControl.vue';
    import AppFormSectionTitle from 'sol-ui/src/components/basic/AppFormSectionTitle.vue';
    import AppFormStatusNotification from 'sol-ui/src/components/basic/AppFormStatusNotification.vue';
    import AppInlineNotification from 'sol-ui/src/components/basic/AppInlineNotification.vue';
    import AppInputText from 'sol-ui/src/components/inputs/AppInputText.vue';
    import AppPageHolder from 'sol-ui/src/components/layout/AppPageHolder.vue';
    import AppPageTitle from 'sol-ui/src/components/basic/AppPageTitle.vue';
    import AppRadioGroup from 'sol-ui/src/components/inputs/AppRadioGroup.vue';
    import AppText from 'sol-ui/src/components/basic/AppText.vue';

    import BlankTitle from '@/components/title/BlankTitle.vue';
    import TitleUtil from '@/components/TitleUtil';
    import { usePreferences } from '@/stores/preferences';

    @Component({
        computed: { ...mapStores(usePreferences) },
        components: {
            AppButton,
            AppCard,
            AppCheckboxGroup,
            AppFormActions,
            AppFormControl,
            AppFormSectionTitle,
            AppFormStatusNotification,
            AppInlineNotification,
            AppInputText,
            AppPageHolder,
            AppPageTitle,
            AppRadioGroup,
            AppText,
            BlankTitle,
        },
    })
    export default class Preferences extends Vue {
        preferencesStore!: ReturnType<typeof usePreferences>;

        async created() {
            TitleUtil.$emit(this, { titleComponentType: BlankTitle, notificationComponentType: '' });
        }

        async mounted() {
            this.preferencesStore.load();
        }
    }
</script>

<style>
  .action-notification {
    text-align: right;
  }
</style>
