// Remove the box-shadow
// https://perishablepress.com/css3-remove-box-shadow/
@mixin no-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;;
  box-shadow: none !important;
}

.no-shadow {
  @include no-shadow
}

@mixin box-shadow-10-2-black {
  // 10px Box shadow, no blur, @2% of black
  box-shadow: 0 10px 0 rgba(0, 0, 0, 0.02)  !important;
}

.box-shadow-10-2-black {
  @include box-shadow-10-2-black;
}

.main-card {
  background-color: var(--v-custom-grey-body-base) !important; // the main card color is #FAFAFA
  @include no-shadow;
  border: 0 !important;
}

.border-1-dashed {
  border: 1px var(--v-custom-grey-2-base) dashed;
}

.border-2-dashed {
  border: 2px var(--v-custom-grey-2-base) dashed;
}