@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.drag-dicom-container {
    background-color: var(--v-custom-grey-body-base) !important; // the main card color is #FAFAFA
    border: 1px var(--v-custom-grey-2-base) dashed;
    font-size: 18px;
    height: fit-content;

    &.over {
        border: 2px var(--v-custom-grey-2-base) dashed;
        border-color: var(--v-custom-blue-button-base) !important;
    }
}
