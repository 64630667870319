@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


// removes underline from <router-link>
a {
    text-decoration: none !important;
}

.back-to-dashboard {
    height: 40px;
    width: 160px;

    font-size: 14px;
    font-weight: 600;
}

.back-to-dashboard .v-icon {
    height: 0.875rem;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    left: 0;
    top: -0.4125rem;
    margin-right: 0.3125rem;
    transition: all 200ms cubic-bezier(0.8, 0, 0.145, 1);
}

.back-to-dashboard .v-icon svg {
    height: 100%;
    width: auto;
}
.back-to-dashboard:hover .v-icon {
    left: -0.5rem;
}
