@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.app-bar-button {
    .app-bar-button-text {
        font-size: 14px;
        font-weight: 600;
        margin-right: 8px;
    }

    button {
        background-color: var(--v-custom-blue-base);

        &:hover {
            background-color: var(--v-custom-blue-button-base);
        }
    }
}
