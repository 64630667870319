@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// The right side column has a fixed width.
// The other columns (the plan content) is adjusted as screen resolution changes
.plan-right-side-column {
    // @see https://developer.mozilla.org/en-US/docs/Web/CSS/flex-basis
    flex-basis: 260px;

    // Given 260px is enough for the content, we set flex-flow to 0, meaning the column cannot grow,
    // and all the other space is occupied by the plan details
    // Note that vuetify <v-col> has flex-grow: 1, so that is the value is overriden.
    flex-grow: 0;
}
