@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

button {
    border: none;
    padding: 0.8125rem $var-layout-gap /2;
    outline: none;
    background: #7C8893;
    min-width: 10rem;
    display: block;
    margin-bottom: 1.25rem;
    color: #fff;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    border-radius: 0.125rem;
    //4px inner shadow, no blur, @10% of black
    box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 14px;
}

button.btn-good {
    background: var(--v-custom-green-base);
}

button.btn-good:hover {
    background: var(--v-custom-green-rich-base);
}

button.btn-warn {
    background: var(--v-custom-orange-base);
}

button.btn-warn:hover {
    background: var(--v-custom-orange-rich-base);
}

button.btn-action {
    background: var(--v-custom-blue-button-base);
}

button.btn-action:hover {
    background: var(--v-custom-blue-base);
}

button.btn-bad {
    background: var(--v-custom-red-base);
}

button.btn-bad:hover {
    background: var(--v-custom-red-rich-base);
}

//button.btn-large {
//    font-size: 1.125rem;
//    padding: 1.3125rem 1.25rem;
//    min-width: 15rem;
//}
button.btn-naked {
    box-shadow: none;
    background: none !important;
    color: var(--v-custom-red-base);

    :deep(.icon), :deep(i.v-icon) {
        color: var(--v-custom-red-base);
    }
}

button.btn-naked:hover {
    color: var(--v-custom-red-rich-base);

    :deep(.icon), :deep(i.v-icon) {
        color: var(--v-custom-red-rich-base);
    }
}

:deep(.icon), :deep(i.v-icon) {
    height: 0.875rem;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -0.0633rem;
    color: #FFF;
}

