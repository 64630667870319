@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.formus-hip-trademark {
    color: var(--v-custom-black-base);
}

.dicom-card {
    p {
        max-width: 85%;
    }
}
