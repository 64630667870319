@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.app-bar-menu-content {
    box-shadow: none;
    background: none;

    .svg-container {
        height: 40px;

        svg {
            fill: var(--v-custom-blue-base);
            width: 1.25rem;
            height: auto;
            position: absolute;
            top: 30px;
            left: auto;
            bottom: 100%;
            right: 0.625rem;
        }
    }

    .app-bar-menu-content-list {
        padding: $var-layout-gap*2 0;

        background-color: var(--v-custom-blue-base);
        font-size: 14px;
        font-weight: 600;

        // deep list item styles
        :deep(.v-list-item) {
            padding: 0 $var-layout-gap;

            .v-list-item__content {
                color: var(--v-custom-grey-body-base);
            }

            &:hover {
                background-color: var(--v-custom-other-blue-base);
            }
        }

        // deep list item styles
        :deep(.v-list-item.v-list-item--disabled) {
            .v-list-item__content {
                color: rgba(255, 255, 255, 0.5);
            }
        }

        // deep list item styles
        :deep(hr) {
            margin: 0 $var-layout-gap;
            border-top-width: 1px !important;
            border-color: var(--v-custom-other-blue-base);
        }
    }
}
