/*
    An application level include file for 'fonts' used in the application.

    Note: The convention of using an underscore ('_') prefix for included files
    is not used as this is deemed to be an outdated convention.
*/

/**
    The material design icon font is used across the application.

    By default all formats are packaged, but modern web browsers will only use `.woff2` format. Instead
    of including the main `materialdesignicons.sass' file, include

    see
      - https://stackoverflow.com/questions/11002820/why-should-we-include-ttf-eot-woff-svg-in-a-font-face
      - https://caniuse.com/woff2
 */
$mdi-font-path: '~@/../node_modules/@mdi/font/fonts';
// @import '~@/../node_modules/@mdi/font/scss/materialdesignicons'; // Ensure you are using sass-loader
@import "~@/../node_modules/@mdi/font/scss/variables";
@import "~@/../node_modules/@mdi/font/scss/functions";
// Don't use the standard `path.scss` definition. Rewrite the MDI font so that only the woff2 format is supported
@font-face {
  font-family: '#{$mdi-font-name}';
  src: url('#{$mdi-font-path}/#{$mdi-filename}-webfont.woff2?v=#{$mdi-version}') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@import "~@/../node_modules/@mdi/font/scss/core";
@import "~@/../node_modules/@mdi/font/scss/icons";
@import "~@/../node_modules/@mdi/font/scss/extras";
@import "~@/../node_modules/@mdi/font/scss/animated";

/*
    Proxima Nova
    ============

    The formus labs application uses the 'Proxima Nova' Font. Only the 'woff' form of the
    fonts are packaged - the opentype and truetype formats are **not** provided/packaged.
    Six (6) forms of the font are packaged and available to the application:

        - ProximaNova-Regular.woff   - Regular
        - ProximaNova-RegularIt.woff  - Regular Italic font files
        - ProximaNova-Bold.woff - Bold
        - ProximaNova-BoldIt.woff - Bold Italic
        - ProximaNova-Semibold.woff - Semi-bold
        - ProximaNova-SemiboldIt.woff - Semi-bold Italic
*/
$pn-font-semi-bold: 600; // between 500 (regular) and 700 (bold)
$pn-font-path: '~@/../node_modules/font-proxima-nova-scss/fonts';
//@import 'node_modules/font-proxima-nova-scss/font-proxima-nova-scss';

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$pn-font-path}/ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$pn-font-path}/ProximaNova-RegularIt.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$pn-font-path}/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$pn-font-path}/ProximaNova-BoldIt.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$pn-font-path}/ProximaNova-Semibold.woff") format("woff");
  font-weight: $pn-font-semi-bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$pn-font-path}/ProximaNova-SemiboldIt.woff") format("woff");
  font-weight: $pn-font-semi-bold;
  font-style: italic;
}
