@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.navigation-sidebar {
    //transform: translateX(0) !important;
    //visibility: visible !important;
    top: 160px !important;

    padding: $var-layout-gap*2 $var-layout-gap*2 $var-layout-gap*4;
    margin-right: 0;

    .footer-nav {
        a {
            text-decoration: none;
            color: var(--v-custom-blue-button-base);

            &:hover {
                color: var(--v-custom-blue-base);
            }
        }
    }

    :deep(.v-navigation-drawer__border) {
        margin-top: $var-layout-gap*2;
        // Given margin-bottom: 80px does not work
        // We set the height to consider the margin top (40px) and margin-bottom (80px)
        height: calc(100% - #{$var-layout-gap*2 + $var-layout-gap*4 });
    }
}
