@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.drag-dicom-container {
    background-color: var(--v-custom-grey-body-base) !important;
    border: 1px var(--v-custom-grey-2-base) dashed;
    font-size: 18px;
}

.drag-dicom-container:hover {
    border: 2px var(--v-custom-grey-2-base) dashed;
    border-color: var(--v-custom-red-base) !important;
}

