@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// Support for hover interaction
//
// Detail: Removes the background of the v-menu and v-card, so the div at the top of
// the navigation is transparent.
// This serves the purpose of allow to transition from the 'hover' event of the button to the navigation menu
// TODO: This code is repeated so something is not quiet right.
.notifications-menu {
  overflow-y: auto;
  box-shadow: none;
  background: none;

  .notifications-card {
    box-shadow: none;
    background: none;

    .svg-container {
      height: 40px;

      svg {
        fill: var(--v-custom-blue-rich-base);
        width: 1.25rem;
        height: auto;
        position: absolute;
        top: 30px;
        left: auto;
        bottom: 100%;
        right: 0.625rem;
      }
    }

    .notifications-card-list {
      padding: $var-layout-gap*2 0;

      background-color: var(--v-custom-blue-rich-base);

      .no-notification-container {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
